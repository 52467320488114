.heading-style {
  font-size: var(--font-lg) !important;
  font-weight: 400;
  font-family: "Graphik_font_regular";
  font-size: 48px;
  font-weight: 400;
  word-spacing: -0.04ch;
  letter-spacing: -0.05ch;
  max-width: 347px;
  text-align: left;
}

.small-text-grey {
  font-size: 16px;
  font-weight: 400;
  font-family: "Graphik_font_regular";
  color: rgb(10, 11, 13);
  max-width: 432px;
}

.email-input {
  width: 100%;
  background-color: #f6f6f6;
  max-width: 380px;
  height: 57px;
  border-radius: 6px;
  border: 1px solid #dfe1e6;
  font-size: 16px;
  font-weight: 400;
  font-family: "Graphik_font_regular";
  color: rgb(10, 11, 13);
  padding: 0px 16px;
}

.privacy-checkbox input {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  border: 1px solid #dfe1e6;
}

.privacy-checkbox label {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: rgb(91, 97, 110);
}

.privacy-checkbox label a {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  text-decoration: none;
  color: black;
}

.join-waitlist-btn {
  width: 147px;
  height: 56px;
  background-color: var(--color-primary);
  color: var(--color-white);
  border-radius: 8px;
  border: 0;
  font-weight: 600;
  font-size: 16px;
}

.join-waitlist-btn:hover {
  opacity: 0.9;
}

.join-waitlist-bubble-svg {
  margin-top: -40px;
  margin-right: 5px;
}

.white-btn {
  padding: 6px 12px;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.1);
  color: black;
  border-radius: 4px;
  border: 0;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

@media (max-width: 768px) {
  .join-waitlist-bubble-svg {
    margin: unset;
  }
}
