:root {
  --color-primary: #000;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-gray: #5b616e;
  --font-md: 22px;
  --font-xs: 14px !important;
  --font-3xl: 185px;
  --font-xl: 65px;
  --font-lg: 42px;
}

@media (min-width: 1600px) {
  :root {
    --font-md: 22px;
    --font-xs: 14px !important;
    --font-3xl: 205px;
    --font-xl: 65px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  :root {
    --font-3xl: 125px;
    --font-xl: 55px;
  }

  .create_section_height {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .create_section_height {
    min-height: 80vh !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  :root {
    --font-md: 22px;
    --font-xs: 14px !important;
    --font-3xl: 95px;
    --font-xl: 55px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .create_section_height {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  :root {
    --font-md: 22px;
    --font-xs: 14px !important;
    --font-3xl: 85px;
    --font-xl: 45px;
  }
}

@media (max-width: 576px) {
  :root {
    --font-md: 20px;
    --font-xs: 13px;
    --font-3xl: 65px;
    --font-xl: 35px;
  }

  .create_section_height {
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@font-face {
  font-family: Graphik_font_Black;
  src: url(./assets/fonts/GraphikMedium.otf);
}

@font-face {
  font-family: Graphik_font_SemiBold;
  src: url(./assets/fonts/GraphikSemibold.otf);
}

@font-face {
  font-family: Graphik_font_Bold;
  src: url(./assets/fonts/GraphikBold.otf);
}

@font-face {
  font-family: Graphik_font_regular;
  src: url(./assets/fonts/GraphikRegular.otf);
}

.fw-bold {
  font-family: "Graphik_font_Bold";
}

.fs-md {
  font-size: var(--font-md);
}

.logo_text {
  font-size: var(--font-md);
  color: var(--color-primary);
  font-family: "Graphik_font_SemiBold";
}

.color_black {
  color: var(--color-black) !important;
}

.fw-medium {
  font-family: "Graphik_font_Black" !important;
}

.common_btn {
  font-size: var(--font-xs);
  background-color: var(--color-primary);
  color: var(--color-white);
  font-family: "Graphik_font_Black";
}

.common_btn:hover {
  font-size: var(--font-xs);
  background-color: var(--color-primary);
  color: var(--color-white);
  opacity: 0.8;
  font-family: "Graphik_font_Black";
}

.faq_text {
  font-size: var(--font-xs);
  color: var(--color-gray);
  font-family: "Graphik_font_regular";
}
.faq_text:hover {
  font-weight: 500;
  color: black;
}

.common_head_text {
  font-size: var(--font-3xl);
  line-height: 100%;
  color: var(--color-black);
  font-family: "Graphik_font_regular";
}

@media (min-width: 1200px) {
  .mt_min_50 {
    margin-top: -50px;
  }
}

.coming_soon_text {
  font-size: var(--font-xl);
  font-family: "Graphik_font_regular";
  color: var(--color-black);
}

.join_text {
  font-size: var(--font-md);
  color: var(--color-black);
}

.nft-section {
  position: relative;
  width: 83vw;
  margin-left: auto;
  margin-right: auto;
  height: 24.5529vw;
}

.first_sub,
.second_sub,
.third_sub,
.four_sub,
.five_sub {
  border: 0.5px solid #e6e6e6;
}
.first_img_section {
  position: absolute;
  top: 4.86914vw;
  left: 0vw;
  width: 14.193vw;
  height: 14.193vw;
}

.first_sub {
  background-size: cover;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.first_img_sub {
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.first_text_element {
  position: absolute;
  top: 4px;
  right: 4px;
  color: var(--color-white);
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  padding: 6px 12px;
  border-radius: 999px;
  font-size: var(--font-xs);
  line-height: 1;
}

.navbar_fixed_top {
  width: 100%;
  z-index: 999;
  transition: all 0.4s ease-in-out;
  padding-bottom: 12px;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.footer-links {
  color: var(--color-gray);
  font-size: var(--font-xs);
}
.footer-links:hover {
  color: black;
  font-weight: 400;
}
/* second image */

.second_img_section {
  position: absolute;
  top: 3.41876vw;
  left: 14.193vw;
  width: 18.6478vw;
  height: 18.6478vw;
}

.second_sub {
  background-size: cover;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.second_img_sub {
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.second_text_element {
  position: absolute;
  top: 4px;
  right: 4px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  padding: 6px 12px;
  border-radius: 999px;
  font-size: clamp(9px, 2.1vmin, 14px);
  line-height: 1;
}

/* third img */

.third_img_section {
  position: absolute;
  top: 9.01309vw;
  left: 32.8408vw;
  width: 20.0981vw;
  height: 11.1887vw;
}

.third_sub {
  background-size: cover;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.third_img_sub {
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.third_text_element {
  position: absolute;
  top: 4px;
  right: 4px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  padding: 6px 12px;
  border-radius: 999px;
  font-size: clamp(9px, 2.1vmin, 14px);
  line-height: 1;
}

/* four img */

.four_img_section {
  position: absolute;
  top: 0vw;
  left: 52.9389vw;
  width: 14.193vw;
  height: 24.5529vw;
}

.four_sub {
  background-size: cover;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.four_img_sub {
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.four_text_element {
  position: absolute;
  top: 4px;
  right: 4px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  padding: 6px 12px;
  border-radius: 999px;
  font-size: clamp(9px, 2.1vmin, 14px);
  line-height: 1;
}

/* five */

.five_img_section {
  position: absolute;
  top: 4.55834vw;
  left: 67.132vw;
  width: 14.193vw;
  height: 14.193vw;
}

.five_sub {
  background-size: cover;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.five_img_sub {
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.five_text_element {
  position: absolute;
  top: 4px;
  right: 4px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  padding: 6px 12px;
  border-radius: 999px;
  font-size: clamp(9px, 2.1vmin, 14px);
  line-height: 1;
}

/* six */
.six_img_section {
  position: absolute;
  top: 9.01309vw;
  left: 81.325vw;
  width: 13.5714vw;
  height: 13.5714vw;
}

.six_sub {
  background-size: cover;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.six_img_sub {
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.six_text_element {
  position: absolute;
  top: 4px;
  right: 4px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  padding: 6px 12px;
  border-radius: 999px;
  font-size: clamp(9px, 2.1vmin, 14px);
  line-height: 1;
}

@media (max-width: 768px) {
  .overflow-x-md-hidden {
    overflow-x: hidden;
  }
  .first_img_section {
    position: absolute;
    top: 0vw;
    left: 0vw;
    width: 52.6033vw;
    height: 51.8182vw;
  }

  .nft-section {
    position: relative;
    margin-left: unset !important;
    margin-right: unset !important;
    left: 2.5vw;
    width: 95vw;
    height: 191vw;
  }

  .first_sub {
    background-size: cover;
    position: absolute;
    inset: 0px;
    overflow: hidden;
  }

  .first_img_sub {
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .first_text_element {
    position: absolute;
    top: 4px;
    right: 4px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
    padding: 6px 12px;
    border-radius: 999px;
    font-size: clamp(9px, 2.1vmin, 14px);
    line-height: 1;
  }

  /* second image */

  .second_img_section {
    position: absolute;
    top: 8.63636vw;
    left: 52.6033vw;
    width: 42.3967vw;
    height: 43.9669vw;
  }

  .second_sub {
    background-size: cover;
    position: absolute;
    inset: 0px;
    overflow: hidden;
  }

  .second_img_sub {
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .second_text_element {
    position: absolute;
    top: 4px;
    right: 4px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
    padding: 6px 12px;
    border-radius: 999px;
    font-size: clamp(9px, 2.1vmin, 14px);
    line-height: 1;
  }

  /* third img */

  .third_img_section {
    position: absolute;
    top: 51.8182vw;
    left: 0vw;
    width: 95vw;
    height: 54.1736vw;
  }

  .third_sub {
    background-size: cover;
    position: absolute;
    inset: 0px;
    overflow: hidden;
  }

  .third_img_sub {
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .third_text_element {
    position: absolute;
    top: 4px;
    right: 4px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
    padding: 6px 12px;
    border-radius: 999px;
    font-size: clamp(9px, 2.1vmin, 14px);
    line-height: 1;
  }

  /* four img */

  .four_img_section {
    position: absolute;
    top: 105.992vw;
    left: 47.1074vw;
    width: 48.6777vw;
    height: 84.7934vw;
  }

  .four_sub {
    background-size: cover;
    position: absolute;
    inset: 0px;
    overflow: hidden;
  }

  .four_img_sub {
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .four_text_element {
    position: absolute;
    top: 4px;
    right: 4px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
    padding: 6px 12px;
    border-radius: 999px;
    font-size: clamp(9px, 2.1vmin, 14px);
    line-height: 1;
  }

  /* five */

  .five_img_section {
    position: absolute;
    top: 105.992vw;
    left: 0vw;
    width: 47.1074vw;
    height: 51.8182vw;
  }

  .five_sub {
    background-size: cover;
    position: absolute;
    inset: 0px;
    overflow: hidden;
  }

  .five_img_sub {
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .five_text_element {
    position: absolute;
    top: 4px;
    right: 4px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
    padding: 6px 12px;
    border-radius: 999px;
    font-size: clamp(9px, 2.1vmin, 14px);
    line-height: 1;
  }

  /* six */
  .six_img_section {
    position: absolute;
    top: 158.595vw;
    left: 0vw;
    width: 47.1074vw;
    height: 48.6777vw;
  }

  .six_sub {
    background-size: cover;
    position: absolute;
    inset: 0px;
    overflow: hidden;
  }

  .six_img_sub {
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .six_text_element {
    position: absolute;
    top: 4px;
    right: 4px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(6px);
    padding: 6px 12px;
    border-radius: 999px;
    font-size: clamp(9px, 2.1vmin, 14px);
    line-height: 1;
  }
}

.btn_comint_soon {
  background-color: var(--color-black);
  color: var(--color-white);
  font-family: "Graphik_font_regular";
  font-size: var(--font-sm);
  box-shadow: 10px 4px 100px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.btn_comint_soon:hover {
  background-color: var(--color-black);
  color: var(--color-white);
  font-size: var(--font-sm);
  opacity: 0.8;
}

.btn_modified {
  background-color: var(--color-white);
  font-family: "Graphik_font_regular";
  font-size: var(--font-sm);
  color: var(--color-black);
  box-shadow: 10px 4px 100px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.logo-style img {
  max-width: 200px;
}

@media (min-width: 576px) {
  .mt-minus-20 {
    margin-top: -20px;
  }
}

.rank_number {
  font-size: var(--font-md);
  font-family: "Graphik_font_semibold";
  color: var(--color-black);
}

.cursor-pointer {
  cursor: pointer;
}

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 2px solid black;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: transparent;
  border: 2px solid black;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: transparent;
  border: 2px solid black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 7px;
  top: 4px;
  width: 8px;
  height: 12px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 576.98px) {
  .logo-style img {
    max-width: 140px;
  }

  .margin-left-m-125 {
    margin-left: -125px;
  }
  .first_sub,
  .second_sub,
  .third_sub,
  .four_sub,
  .five_sub {
    border: 1px solid #e6e6e6;
  }
}

.z-999 {
  z-index: 999 !important;
}
